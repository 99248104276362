import React from "react"
import { DayPickerSingleDateController } from "react-dates"
import moment from "moment"

import { datepickerStyle } from "./datepickerStyle"

import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"

const MAX_DAYS = 330

class SingleDatePicker extends React.Component {
  static defaultProps = {
    minDate: moment(),
    maxDate: moment().add(MAX_DAYS, "days"),
    selectedDate: null
  }

  onFocusChanged = args => {
    const { onFocusChange } = this.props
    onFocusChange(!!args.focused)
  }

  isOutsideRange = (day: Moment) => {
    const { minDate, maxDate } = this.props
    return day.isBefore(minDate) || day.isAfter(maxDate)
  }

  render() {
    const { selectedDate, onDateChange, colors, numberOfMonths, orientation } = this.props

    return (
      <div className={`${datepickerStyle(colors)}`}>
        <DayPickerSingleDateController
          date={selectedDate}
          onDateChange={onDateChange}
          focused={true}
          numberOfMonths={numberOfMonths}
          onFocusChange={this.onFocusChanged}
          orientation={orientation}
          isOutsideRange={this.isOutsideRange}
          hideKeyboardShortcutsPanel={true}
        />
      </div>
    )
  }
}

export default SingleDatePicker
